import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { saveUserReduxState } from 'stores/User';
import { actionCreators } from 'stores/User';
import {
    RadioButton,
    TextField,
    TextFieldSelect,
    Button,
} from '@partssourceinc/react-ui-core';
import usePartsSourceOems from 'hooks/usePartsSourceOems';
import 'less/advancedLists.less';
import axios from 'axios';
import AddAssetId from './AddAssetId';
import { useHistory } from 'react-router-dom';
import UploadFiles from '../../components/UploadFiles';
import styled from 'styled-components';
import FacilityDialog from 'components/FacilitySearchDialog';

function CreateOrUpdateAdvancedList(props) {
    const { saveList, getLists } = actionCreators;
    const history = useHistory();
    const dispatch = useDispatch();

    const { user, oems } = useSelector((state) => ({
        user: state.user,
        oems: state.companies.oems,
    }));

    const partsSourceOems = usePartsSourceOems();
    const [showErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [name, setListName] = useState('');
    const [description, setDescription] = useState('');
    const [selectedList, setSelectedList] = useState(null);
    const [selectedPrivacy, setSelectedPrivacy] = useState('0');
    const [models, setModels] = useState([]);
    const [oem, setSelectedOem] = useState();
    const [model, setSelectedModel] = useState();
    const [assetIds, setAssetIds] = useState(['']);
    const [groupName, setGroupName] = useState(null);
    const [showFacilityDialog, setShowFacilityDialog] = useState(false);
    const [attachments, setAttachments] = useState([]);

    const facilityId = useSelector(state => state.user.facility.facilityId);

    useState(() => {
        if (facilityId > 0) {
            fetchListDetail();
        } else {
            setShowFacilityDialog(true);
        }
    }, []);

    const isEditMode = () => getRavenId() !== null && getRavenId() !== undefined;

    function getRavenId() {
        return props.match.params.id;
    }

    function fetchListDetail(fId) {
        const id = fId || facilityId;
        const ravenId = getRavenId();
        if (ravenId) {
            setLoading(true);
            axios
                .get(`ShoppingService/api/v1/lists/${id}/${ravenId}`)
                .then((x) => {
                    const { name, description, type, equipmentInfo, files, facilityIds, rootCompanyIds } = x.data.list;

                    setListName(name);
                    setDescription(description);
                    setSelectedList(type);

                    let privacy = '0';
                    if (facilityIds.length > 0) privacy = '1';
                    if (rootCompanyIds.length > 0) privacy = '2';
                    setSelectedPrivacy(privacy);

                    setGroupName(x.data.groupName);

                    if (equipmentInfo) {
                        setSelectedOem(equipmentInfo.oem);
                        let _models = [];

                        if (equipmentInfo && equipmentInfo.oem) {
                            axios.get(`CatalogService/api/v1/${equipmentInfo.oem.id}/models`).then((x) => {
                                if (!x.data.some((x) => x.modelId === -1)) {
                                    _models = [{ modelId: -1, model: '(MODEL NOT LISTED)' }, ...x.data];
                                }
                                setModels(_models);

                                if (equipmentInfo.model != null) {
                                    let _model = {
                                        modelId: equipmentInfo.model.id,
                                        model: equipmentInfo.model.name,
                                    }
                                    setSelectedModel(_model);
                                }
                            });
                        }

                        setAssetIds(equipmentInfo.assetIds.length > 0 ? equipmentInfo.assetIds : ['']);
                        setAttachments(files);
                    }
                    setLoading(false);
                });
        }
    }

    const handleOnChangeAssetId = (val, index) => {
        let data = [...assetIds];
        data[index] = val;
        setAssetIds(data);
    };

    const addNew = () => {
        if (assetIds.some((x) => !x)) {
        } else {
            setAssetIds([...assetIds, '']);
        }
    };

    const handleOnSave = useCallback(() => {
        setLoading(true);
        const data = {
            ravenId: getRavenId(),
            name,
            description,
            type: parseInt(selectedList),
            privacy: selectedPrivacy,
            equipmentInfo: {
                oem: oem ? { id: oem.id, name: oem.name } : null,
                model: model ? { id: model.modelId, name: model.model } : null,
                assetIds: assetIds.filter((x) => x !== ''),
            },
            attachments,
            facilityId,
        };

        data.psPartNumber = props.match.params.psPartNumber;
        data.quantity = props.match.params.quantity;
        data.partNumber = props.match.params.partNumber;

        const oemSeo = props.match.params.oemSeo;
        let pdpUrl = `/parts/${oemSeo}/${data.partNumber}?pspn=${data.psPartNumber}`;

        if (history && history.location && history.location.state && history.location.state.option) {
            const { option } = history.location.state;

            data.productId = option.productId;
            data.purchaseChoice = option.purchaseChoice;
            data.condition = option.condition;
            data.isVariantProduct = option.isVariantProduct;
            data.vString = option.vString;

            pdpUrl = `${pdpUrl}&vrnt=${option.vString}`;
        }

        let origin = 'My Lists';
        if (data.psPartNumber) origin = 'PDP';
        else if (props.location.search.indexOf('origin') !== -1) origin = 'My Lists Menu';

        data.origin = origin;

        dispatch(saveList(data)).then(x => {
            setLoading(false);
            dispatch(getLists());

            if (data.psPartNumber == undefined) {
                history.push(`/list/${x.response.data.id}`);
            } else {
                history.push(pdpUrl);
            }
        });
    });

    const handleOnSelecteOEM = (item) => {
        let _models = [];

        axios.get(`CatalogService/api/v1/${item.id}/models`).then((x) => {
            if (!x.data.some((x) => x.modelId === -1)) {
                _models = [{ modelId: -1, model: '(MODEL NOT LISTED)' }, ...x.data];
            }

            setSelectedOem(item);
            setSelectedModel(null);
            setModels(_models);
        });
    };

    const showRemoval = () => isEditMode() ? (groupName === 'mylist' ? true : false) : true;

    const onSelectFacility = (facility) => {
        dispatch(saveUserReduxState({ facility: facility }));
        setShowFacilityDialog(false);
        fetchListDetail(facility.facilityId);
    };

    return (
        <div className="create-advanced-list container">
            {loading && <div className="loading-data"><div className="loader" /></div>}
            <img src={`/images/${isEditMode() ? 'icn_edit.png ' : 'icn_create.png'}`} className={`icon ${isEditMode() ? 'icon_edit' : ''} hidden-xs`} />

            <h1 className="title" style={{ textAlign: 'center' }}>
                {' '}
                {isEditMode() ? 'Edit' : 'Create New'} List{' '}
            </h1>
            <TextField
                label="List Name"
                placeholder="New List Name"
                onChange={(e) => setListName(e.target.value)}
                className="list-name"
                text={name}
                showErrorMessage={showErrors && !name}
                tabIndex={0}
            />
            <hr className="separator" />
            <div>
                <h1 className="subtitle">Privacy</h1>
                {privacyOptions.map((item, i) => (
                    <RadioButton
                        key={i}
                        id={`privacy-option-${i}`}
                        className="list-radio"
                        value={item.id}
                        onChange={(e) => setSelectedPrivacy(e.target.value)}
                        checked={item.id == selectedPrivacy}
                    >
                        <span className="list-item-title">{item.label}</span>
                        <div>
                            <span className="list-item-description">{item.description}</span>
                        </div>
                    </RadioButton>
                ))}
            </div>
            <hr className="separator" />
            <TextField
                id="description"
                className="list-description"
                multiLine={true}
                rows={4}
                text={description}
                required={true}
                label="Description"
                placeholder="Description"
                onChange={(e) => setDescription(e.target.value)}
                showErrorMessage={showErrors && !description}
            />
            <hr className="separator" />
            <div>
                <h1 className="subtitle">List Type:</h1>
                {options.map((item, i) => (
                    <RadioButton
                        key={i}
                        id={`option-${i}`}
                        className="list-radio"
                        value={item.id}
                        onChange={(e) => setSelectedList(e.target.value)}
                        checked={item.id == selectedList}
                    >
                        <span className="list-item-title">{item.label}</span>
                        <div>
                            <span className="list-item-description">{item.description}</span>
                        </div>
                    </RadioButton>
                ))}
            </div>
            {1 === 2 && <hr className="separator" />}
            {1 === 2 && <div>
                <h1 className="subtitle">Add Equipment (optional) </h1>
                <TextFieldSelect
                    id="oemId"
                    keyField="id"
                    valueField="name"
                    className="selectOem"
                    listItems={oems.length === 0 ? partsSourceOems : oems}
                    alwaysShowKey={'26799'}
                    label="OEM"
                    placeHolder="OEM"
                    onSelect={(oem) => handleOnSelecteOEM({ id: oem.id, name: oem.name })}
                    showErrorMessage={showErrors && !oem.oemId}
                    selectedItem={oem}
                    required={true}
                    disableClear={true}
                    tabIndex={0}
                />
                <TextFieldSelect
                    id="modelNumber"
                    keyField="modelId"
                    valueField="model"
                    className="selectModel"
                    alwaysShowKey={'-1'}
                    listItems={models}
                    label="Model Number"
                    placeHolder="Model Number"
                    onSelect={(model) => setSelectedModel(model)}
                    showErrorMessage={showErrors && !model.model}
                    submitDisabled={!oem ? true : undefined}
                    selectedItem={model}
                    allowCreate={false}
                    required={true}
                    disableClear={true}
                    tabIndex={0}
                />
                <div style={{ marginBottom: 30 }}>
                    <AddAssetId
                        elements={assetIds}
                        handleOnChangeAssetId={handleOnChangeAssetId}
                        addNew={addNew}
                    />
                </div>
                {1 === 2 && <UploadFiles setAttachments={setAttachments} attachments={attachments} showRemoval={showRemoval()} />}
            </div>
            }
            <hr className="separator" />
            <div>
                <div className="actions" style={{ marginBottom: 30, width: 390 }}>
                    <a className="cancel" onClick={() => history.goBack()}>
                        Cancel
                    </a>

                    <div
                        className="col-xs-12 d-block d-sm-none"
                        style={{ paddingLeft: '10%', paddingRight: '10%', marginTop: 30 }}
                    >
                        <div className="row">
                            <div className="col-xs-5">
                                <hr />
                            </div>
                            <div className="col-xs-2 or">OR</div>
                            <div className="col-xs-5">
                                <hr />
                            </div>
                        </div>
                    </div>

                    <Button
                        className="btnSave"
                        disabled={!name || !description || selectedList === null}
                        onClick={handleOnSave}
                        loading={loading}
                    >
                        Save
                    </Button>
                </div>
            </div>
            {showFacilityDialog && <FacilityDialog onSubmit={onSelectFacility} onCancel={() => showFacilityDialog(false)} disableClose={!(facilityId > 0)} />}
        </div>
    );
}

const options = [
    {
        id: 0,
        label: 'Shopping List',
        description: 'General list for items you are shopping for.',
    },
    {
        id: 1,
        label: 'Repair List',
        description: 'Items needed for a specific repair on equipment.',
    },
    {
        id: 2,
        label: 'Preventative Maintanance List',
        description:
            'Items used periodically for preventative maintanence on equipment.',
    },
    {
        id: 3,
        label: 'Restocking List',
        description: 'Repeat orders to restock stockrooms or general supplies.',
    },
];

const privacyOptions = [
    {
        id: 0,
        label: 'Only Me',
        description: 'Only I will be able to see this list.',
    },
    {
        id: 1,
        label: 'My Facility',
        description: 'List that can be seen by others at your facility.',
    },
    {
        id: 2,
        label: 'My Company',
        description: 'List that can be seen by others within your organization.',
    },
];

export default withRouter(CreateOrUpdateAdvancedList);
