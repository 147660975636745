import React from 'react';
import {TextField} from '@partssourceinc/react-ui-core';
import 'less/advancedLists.less';

export default function AddAssetId({addNew, handleOnChangeAssetId, elements}) {

    return elements.map((item, i) => (
        <div className="assetItemContainer">
            <div className="row asetItem" key={i}>
                <div className={i === elements.length - 1 ? 'col-xs-10' : 'col-xs-12'} style={{padding: 0}} >
                    <TextField
                        text={item}
                        label="Asset ID"
                        placeholder="New Asset ID"
                        onChange={(e) => handleOnChangeAssetId(e.target.value, i)} />
                </div>
                {i === elements.length - 1 && (
                    <div className="col-xs-2">
                        <button className="btnAdd" onClick={addNew}>+</button>
                    </div>
                )}
            </div>
        </div>
    ));
}
